@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
.container-header{
    font-family: 'Mulish';
    font-size: 2rem;
    padding: .5rem;
    background-color: #001f3f36;
}

.accordion-header {
    cursor: pointer;
    font-family: 'Mulish';
    
}

.content{
    display: flex;
    justify-content: space-between;
    font-family: 'Quicksand';
    padding: 0 .1rem;
    font-weight: 500;
    cursor: pointer;
    
}

.content a{
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: space-between;
}


.content:hover .description{
    font-weight: 600;
}

.content:hover .number{
    font-weight: 600;
}

.description{
    text-transform: capitalize;
    font-size: .9rem;
    font-weight: 500;
}

.number{
    font-size: .8rem;
}

.accordion-header.open {
    background-color: #001f3f9c;
    color: #fff;
}
.accordion-content {
    overflow: hidden;
    height: 0;
    transition: all 200ms;
}

.accordion-content.open {
    height: auto;
    padding: .2rem;
    display: block;
    
}