
body {
  margin: 0;
  background-color: #00000010;
  
}

/* Estilos para navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do fundo da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: #001F3F; /* Cor da barra de rolagem */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
